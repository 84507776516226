import React from 'react';
import classNames from 'classnames';
import useFontClassName from '@app/external/common/hooks/use-font-class-name';
import useIsFeedDesignEnabled from '@app/external/common/hooks/use-is-feed-design-enabled';
import { type NormalizedPost } from '@app/external/common/types';
import Link from '../../../common/components/link/internal-link';
import { PostFooter } from '../../../common/components/post-footer';
import { HorizontalSeparator } from '../../../common/components/separator';
import RecentPostListItemCover from '../recent-post-list-item-cover';
import styles from './recent-posts-list-item.scss';

type RecentPostsListItemWithCoverProps = {
  post: NormalizedPost;
  isMetadataFooterVisible: boolean;
};

const RecentPostsListItemWithCover: React.FC<RecentPostsListItemWithCoverProps> = ({
  post,
  isMetadataFooterVisible,
}) => {
  const { applyFeedDesign, getPostClassName } = useIsFeedDesignEnabled();
  const { titleFontClassName, contentFontClassName } = useFontClassName();

  const titleContainerClassName = classNames(
    !applyFeedDesign && titleFontClassName,
    getPostClassName('title-font'),
  );
  const titleClassName = classNames(styles.titleWrapper, 'blog-post-title-color');
  const containerClassName = classNames(
    styles.containerWithCover,
    !isMetadataFooterVisible && styles.withoutFooter,
    !applyFeedDesign && contentFontClassName,
    getPostClassName('description-font'),
  );

  const postLink = `/${post.slug}`;

  return (
    <div className={containerClassName}>
      <RecentPostListItemCover post={post} />
      <div className={classNames(styles.main, 'with-cover')}>
        <div className={titleContainerClassName} data-hook="recent-post__title">
          <p className={titleClassName}>
            <Link
              className={classNames(styles.title, styles.titleClamp)}
              to={postLink}
              fullRoute={post.link}
            >
              {post.title}
            </Link>
          </p>
        </div>
        {isMetadataFooterVisible && (
          <div className={styles.footerWrapper}>
            <HorizontalSeparator className={styles.separator} />
            <div className={styles.footer}>
              <PostFooter post={post} displayIcons />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default RecentPostsListItemWithCover;
