import React from 'react';
import { resolveId } from '@wix/communities-blog-client-common';
import { CommentCountCompact } from '@app/external/comments/components/comment-count-compact';
import { LikeButtonWithCount } from '@app/external/common/components/like-button-with-count';
import PostIconRow from '@app/external/common/components/post-icon-row';
import PostSocialActions from '@app/external/common/components/post-social-actions';
import { useSelector } from '@app/external/common/components/runtime-context';
import { HorizontalSeparator } from '@app/external/common/components/separator';
import ViewCountCompact from '@app/external/common/components/view-count-compact';
import {
  getCommentCount,
  getCommentCountFormatted,
  getViewCount,
} from '@app/external/common/store/post-counters/post-counters-selectors';
import type { NormalizedPost } from '@app/external/common/types';
import { usePostPageShareProviders } from '../../hooks/use-post-page-share-providers';
import { useSettingsContext } from '../post/settings-context';
import styles from './post-main-actions-mobile.scss';

const PostMainActionsMobile: React.FC<{ post: NormalizedPost }> = ({ post }) => {
  const { showLikeCount, showCommentCount, showViewCount, isMetadataFooterVisible } =
    useSettingsContext();
  const { providers } = usePostPageShareProviders();

  const postId = resolveId(post);

  const viewCount = useSelector((state) => getViewCount(state, postId));
  const totalComments = useSelector((state) => getCommentCount(state, postId));
  const totalCommentsCountFormatted = useSelector((state) =>
    getCommentCountFormatted(state, postId),
  );

  return (
    <div className={styles.container}>
      {providers.length > 0 ? (
        <PostIconRow className={styles.shareButtons} postId={post.id!}>
          <PostSocialActions
            postSlug={post.slug}
            path={`/${post.slug}`}
            postId={resolveId(post)}
            enabledProviders={providers}
          />
        </PostIconRow>
      ) : null}
      <HorizontalSeparator />
      {isMetadataFooterVisible ? (
        <div className={styles.flexContainer}>
          <div className={styles.stats}>
            {showViewCount ? <ViewCountCompact count={viewCount} /> : null}
            {showCommentCount ? (
              <CommentCountCompact
                count={totalComments}
                countFormatted={totalCommentsCountFormatted}
                showZero={false}
              />
            ) : null}
          </div>
          {showLikeCount ? <LikeButtonWithCount postId={resolveId(post)!} /> : null}
        </div>
      ) : null}
    </div>
  );
};

export default PostMainActionsMobile;
