import { useExperiments } from '@wix/yoshi-flow-editor';
import { useStyles } from '@wix/tpa-settings/react';
import {
  AUTHOR_INFO_TYPE_NAME,
  hasTPACommentsSettings,
  getTPASettingsIsCommentsEnabled,
  RelatedPostsLabel,
} from '@wix/communities-blog-client-common';
import stylesParams from '@app/components/Post/stylesParams';
import { OOI_EXPERIMENTS } from '@app/experiments';
import { useSelector } from '../components/runtime-context';
import {
  getIsViewCountEnabled,
  getIsCommentCountEnabled,
  getIsLikeCountEnabled,
  getIsAuthorNameEnabled,
  getIsPostUpdatedDateEnabled,
  getIsCommentsEnabled,
  getIsCategoryLabelsEnabled,
  getIsPostTitleEnabled,
  getIsPostPublishDateEnabled,
  getIsReadingTimeEnabled,
  getAuthorInfoType,
  getIsAuthorPictureEnabled,
  getIsRelatedPostsEnabled,
  getRelatedPostsLabel,
  getIsCategoryLabelEnabled,
  getIsPostRatingEnabled,
  getIsTagsEnabled,
  getIsMoreOptionsMenuEnabled,
  getIsSeeAllPostsLinkEnabled,
} from '../selectors/app-settings-selectors';
import { getIsPostInPreview } from '../selectors/is-post-in-preview';
import { getMainCategory } from '../store/categories/categories-selectors';
import { type NormalizedPost } from '../types';
import { type UseFeedMetadataSettings } from './use-feed-metadata-settings';

export type UsePostPageSettings = UseFeedMetadataSettings & {
  showRelatedPostsSeeAllLink: boolean;
};

type UsePostPageSettingsParams = {
  post: NormalizedPost;
};

export const usePostPageSettings = (params: UsePostPageSettingsParams): UsePostPageSettings => {
  const styles = useStyles();
  const { experiments } = useExperiments();
  return useSelector((state) => {
    const isPostInPreview = getIsPostInPreview(state);
    const isCommentsEnabled = isPostInPreview
      ? false
      : hasTPACommentsSettings(state)
      ? getTPASettingsIsCommentsEnabled(state)
      : getIsCommentsEnabled(state);

    if (experiments.enabled(OOI_EXPERIMENTS.USE_NEW_POST_PAGE_SETTINGS_URL)) {
      return {
        showComments: isCommentsEnabled,
        showViewCount: styles.get(stylesParams.showViewCount),
        showCommentCount: styles.get(stylesParams.showCommentCount),
        showLikeCount: styles.get(stylesParams.showLikeCount),
        showAuthorName: styles.get(stylesParams.showAuthorName),
        showAuthorPicture: styles.get(stylesParams.showAuthorPicture),
        showPostUpdatedDate: styles.get(stylesParams.showPostUpdatedDate),
        showCategoryLabel: false, // Unused
        showPostDescription: true, // Unused
        showCategoryLabels: styles.get(stylesParams.showCategoryLabel),
        showPostTitle: styles.get(stylesParams.showPostTitle),
        showPublishDate: styles.get(stylesParams.showPostPublishDate),
        showReadingTime: styles.get(stylesParams.showReadingTime),
        showTags: styles.get(stylesParams.showTags),
        showMoreOptionsMenu: styles.get(stylesParams.showMoreOptionsMenu),
        isMetadataFooterVisible:
          styles.get(stylesParams.showViewCount) ||
          styles.get(stylesParams.showCommentCount) ||
          styles.get(stylesParams.showLikeCount),
        isMetadataHeaderVisible:
          styles.get(stylesParams.showAuthorName) ||
          styles.get(stylesParams.showPostPublishDate) ||
          styles.get(stylesParams.showReadingTime),
        showRelatedPosts: styles.get(stylesParams.showRelatedPosts),
        showRelatedPostsSeeAllLink: styles.get(stylesParams.showRelatedPostsSeeAllLink),
        relatedPostsLabelKey: styles.get(stylesParams.showRelatedPostsLabel)
          ? styles.get(stylesParams.showRelatedPostsLabelType) === RelatedPostsLabel.Recent
            ? 'recent-posts.title'
            : 'related-posts.title'
          : undefined,
        showPostRating: styles.get(stylesParams.showPostRating),
      } satisfies UsePostPageSettings;
    }

    const isRelatedPostsEnabled = !isPostInPreview && getIsRelatedPostsEnabled(state);

    const showRelatedPostsSeeAllLink = getIsSeeAllPostsLinkEnabled(state) !== false;
    const relatedPostsLabelKey = !isPostInPreview ? getRelatedPostsLabel(state) : undefined;
    const showViewCount = !isPostInPreview && getIsViewCountEnabled(state);
    const showCommentCount = isCommentsEnabled && getIsCommentCountEnabled(state);
    const showLikeCount = !isPostInPreview && getIsLikeCountEnabled(state);
    const showAuthorName = getIsAuthorNameEnabled(state);
    const authorInfoType = getAuthorInfoType(state);
    const showPostUpdatedDate = getIsPostUpdatedDateEnabled(state);
    const showCategoryLabels = getIsCategoryLabelsEnabled(state);
    const showPostTitle = getIsPostTitleEnabled(state);
    const showPublishDate = getIsPostPublishDateEnabled(state);
    const showReadingTime = getIsReadingTimeEnabled(state);
    const oldShowAuthorPictureValue = authorInfoType !== AUTHOR_INFO_TYPE_NAME;
    const showAuthorPicture = getIsAuthorPictureEnabled(state, oldShowAuthorPictureValue);
    const showCategoryLabel = getMainCategory(state, params.post)
      ? getIsCategoryLabelEnabled(state)
      : false;
    const showPostRating = getIsPostRatingEnabled(state);
    const showTags = getIsTagsEnabled(state);
    const showMoreOptionsMenu = getIsMoreOptionsMenuEnabled(state);

    return {
      isMetadataFooterVisible:
        !isPostInPreview && (showViewCount || showCommentCount || showLikeCount),
      isMetadataHeaderVisible: showAuthorName || showPublishDate || showReadingTime,
      showAuthorName,
      showAuthorPicture,
      showCategoryLabel,
      showCategoryLabels,
      showPostDescription: true,
      showCommentCount,
      showComments: isCommentsEnabled,
      showLikeCount,
      showPostRating,
      showPostTitle,
      showPostUpdatedDate,
      showPublishDate,
      showReadingTime,
      showViewCount,
      showTags,
      showMoreOptionsMenu,

      showRelatedPosts: isRelatedPostsEnabled,
      relatedPostsLabelKey,
      showRelatedPostsSeeAllLink,
    } satisfies UsePostPageSettings;
  });
};
